import { defu } from 'defu'
import type { FetchOptions } from 'ofetch'
import { makeTracingHeaders, useFetch, useNuxtApp } from '#imports'

type Opts = FetchOptions & { [key: string]: string }
const createDefaultOptions = (opts: Opts = {}) =>
  defu(opts, {
    async onRequest({ options }) {
      const { $auth0 } = useNuxtApp()
      const { getAccessTokenSilently } = $auth0()

      const token = await getAccessTokenSilently()

      const headers = {
        Authorization: `Bearer ${token}`,
        'auth-provider': 'auth0',
        ...makeTracingHeaders(),
      }

      options.headers = defu(headers, options.headers)
    },
  })

export const $apiFetcher = (options?: Opts) =>
  $fetch.create(createDefaultOptions(options))

type UseFetch = typeof useFetch

export const useApiFetch: UseFetch = (url, options = {}) =>
  useFetch(url, defu(options, createDefaultOptions()))
