import { default as edit_45type0xnPVzWI6ZMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_93eyf0uxlUvuMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as createevcjGrcDIBMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as indexOvpIy5WSi9Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as enginePU9C24CxsyMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as indexkkO8gXZfdMMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue?macro=true";
import { default as enginesdAkerqk4z3Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexQN6qhi3AErMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as index9vrbOqEYPGMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93qu5GMlvVpvMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as indexepW35Vh59gMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editorLLGk0WQMjIMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexYpdp5QNfCsMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as editdyO1Ms4aXFMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as addCcv5mV8jtdMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as createzashcOLrDYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexDSvGmpbOVXMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93gRuspLi3ixMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as createLp9ChsjLRDMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as indexUcMVjtCI1QMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerEDUsJsBCprMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as region45rGD4VT8CMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documenthJXzGbgJwSMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_451IlAzL3VcGYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_452yrUxJAhIxZMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_93EwyqkDMG4cMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createpQRTQ7yZt1Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as index0DIPsRUAMsMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicLr14skJlSeMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "edit-type",
    path: "/edit-type",
    meta: edit_45type0xnPVzWI6ZMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue")
  },
  {
    name: enginePU9C24CxsyMeta?.name,
    path: "/engine",
    meta: enginePU9C24CxsyMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue"),
    children: [
  {
    name: "engine-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue")
  },
  {
    name: "engine-create",
    path: "create",
    meta: createevcjGrcDIBMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue")
  },
  {
    name: "engine",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue")
  },
  {
    name: policy_45editorLLGk0WQMjIMeta?.name,
    path: "/policy-editor",
    meta: policy_45editorLLGk0WQMjIMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue"),
    children: [
  {
    name: _91id_93qu5GMlvVpvMeta?.name,
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue"),
    children: [
  {
    name: "policy-editor-id-engines",
    path: "engines",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue")
  },
  {
    name: "policy-editor-id",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue")
  },
  {
    name: "policy-editor-id-reference-document",
    path: "reference-document",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue")
  }
]
  },
  {
    name: "policy-editor",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue")
  }
]
  },
  {
    name: "policy-id",
    path: "/policy/:id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue")
  },
  {
    name: "policy-id-reference-document-docId-edit",
    path: "/policy/:id()/reference-document/:docId()/edit",
    meta: editdyO1Ms4aXFMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue")
  },
  {
    name: "policy-id-reference-document-add",
    path: "/policy/:id()/reference-document/add",
    meta: addCcv5mV8jtdMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue")
  },
  {
    name: "policy-create",
    path: "/policy/create",
    meta: createzashcOLrDYMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexDSvGmpbOVXMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue")
  },
  {
    name: providerEDUsJsBCprMeta?.name,
    path: "/provider",
    meta: providerEDUsJsBCprMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue"),
    children: [
  {
    name: "provider-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue")
  },
  {
    name: "provider-create",
    path: "create",
    meta: createLp9ChsjLRDMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue")
  },
  {
    name: "provider",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue")
  }
]
  },
  {
    name: "region",
    path: "/region",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue")
  },
  {
    name: "source-document",
    path: "/source-document",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue")
  },
  {
    name: "source-type-level-1",
    path: "/source-type-level-1",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue")
  },
  {
    name: "source-type-level-2",
    path: "/source-type-level-2",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue")
  },
  {
    name: topicLr14skJlSeMeta?.name,
    path: "/topic",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue"),
    children: [
  {
    name: "topic-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue")
  },
  {
    name: "topic-create",
    path: "create",
    meta: createpQRTQ7yZt1Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue")
  },
  {
    name: "topic",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue")
  }
]
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]