function isValidJSONObject(obj: any) {
  try {
    JSON.stringify(obj)
    return true
  } catch (e) {
    return false
  }
}

interface ApiClientOptions {
  baseURL?: string
}
export const useApiClient = (
  options: ApiClientOptions = { baseURL: '/api/paapi' },
) => {
  const { $auth0, $auth0Ready } = useNuxtApp()
  const { isLoading, getAccessTokenSilently } = $auth0()

  return {
    isReady: $auth0Ready,
    async fetch<T>(url: string, opts?: RequestInit): Promise<T> {
      if (isLoading.value) await $auth0Ready()
      const fullUrl = `${options.baseURL || '/api/paapi'}${url}`
      const headers = new Headers(opts?.headers)

      const token = await getAccessTokenSilently()
      headers.set('auth-provider', 'auth0')
      headers.set('Authorization', `Bearer ${token}`)

      if (
        !headers.has('Content-Type') &&
        opts?.body &&
        isValidJSONObject(opts.body)
      ) {
        headers.set('Content-Type', 'application/json')
        opts.body = JSON.stringify(opts.body)
      }

      const res = await fetch(fullUrl, {
        ...opts,
        headers,
      })

      if (!res.ok)
        throw new Error(res.statusText, {
          cause: {
            type: 'FetchError',
            url: fullUrl,
            status: res.status,
            statusText: res.statusText,
          },
        })

      return res.json()
    },
  }
}
