<script setup lang="ts"></script>

<template>
  <button
    class="bg-secondary hover-shadow focus-shadow min-h-[52px] rounded-lg px-4 py-2 font-semibold text-white"
    type="button"
  >
    <slot />
  </button>
</template>
