import { acceptHMRUpdate, defineStore } from 'pinia'

interface User {
  id: number
  first_name: string
  last_name: string
  email: string
}

interface ActiveUserState {
  isContentTeam: boolean
  roles: string[]
  permissions: string[]
  user: User | null
}

export const useActiveUser = defineStore('active-user', {
  state: (): ActiveUserState => ({
    isContentTeam: false,
    roles: [],
    permissions: [],
    user: null,
  }),

  getters: {
    email: (state) => {
      const { $auth0 } = useNuxtApp()
      const { user } = $auth0()

      return user.value?.email ?? state.user?.email ?? ''
    },
    firstName: (state) => {
      const { $auth0 } = useNuxtApp()
      const { user } = $auth0()

      if (state.user?.first_name) {
        return state.user.first_name
      }

      if (!user.value) return ''

      return user.value.given_name
    },
    fullName: (state) => {
      if (!state.user) return ''

      if (state.user.first_name) {
        return `${state.user.first_name} ${state.user.last_name}`
      }

      const { $auth0 } = useNuxtApp()
      const { user } = $auth0()

      if (!user.value) return ''

      if (user.value?.name !== user.value.email) {
        return user.value.name
      }

      return user.value.given_name
    },
  },

  actions: {
    async fetchUser() {
      const apiClient = useApiClient({ baseURL: '/api' })

      const user = await apiClient.fetch<ActiveUserState>('/user')

      this.$patch(user)
      return user
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useActiveUser, import.meta.hot))
