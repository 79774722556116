<script setup lang="ts">
import { useActiveUser } from '~/stores/activeUser'

const activeUser = useActiveUser()
const { $auth0, $pendo, $datadog } = useNuxtApp()
const { user } = $auth0()
const route = useRoute()
const config = useRuntimeConfig().public

useHead(() => ({
  title: (route.meta.title as string) || undefined,
  titleTemplate: (s) => {
    return s && s !== 'Policy Manager' ? `${s} | Policy Manager` : 'Policy Manager'
  },
}))

const { data: userData } = await useAsyncData(
  'activeUser',
  () => activeUser.fetchUser(),
  {
    immediate: true,
    watch: [user],
  },
)

const userPermissions = computed(() => userData.value?.permissions || [])

provide('user-permissions', userPermissions)

watch(
  user,
  (userData) => {
    if (userData?.email && userData.sub) {
      const { email, sub: id, name: full_name } = userData

      const roles: string[] = userData['https://auth.rialtic.io/roles']

      $datadog.setUser({
        id,
        name: full_name,
        email,
      })

      $pendo.identify({
        visitor: {
          id: email,
          email,
          full_name,
          role: roles[0],
        },
        account: {
          id: email?.split('@')[1],
          rialtic_app: 'policy_manager',
          is_internal_app: true,
        },
      })
    }
  },
  {
    immediate: true,
  },
)

const getRefData = async (code: string, model: string) => {
  const client = $apiFetcher()

  const data = await client(
    `${config.WORKERS_API_BASE_URL}/ref-data/${model}`,
    {
      params: {
        search_phrase: model === 'Diagnosis' ? code.replace('.', '') : code,
      },
    },
  )

  return data?.length ? data[0] : null
}

provide('getRefData', getRefData)
</script>

<template>
  <NuxtPwaManifest />
  <div class="font-poppins bg-surface-bg">
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#6F7CCD 0%,#28B7F0 62%,#35CDDA 100%)"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AuthLoading :message="config.auth.loginMessage" />
  </div>
</template>
